import React, { useState } from "react";
import "../style.css";
import { MdEmail } from "react-icons/md";
import { BsFillKeyFill } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import Home_page_header from "./home_page_header";
import Swal from 'sweetalert2'
import Home_footer from "./Home_footer";
import Chatbot from "./Chatbot/Chatbot";
import Header from "./Header";
const HomePage = () => {
  const navigate = useNavigate();
  const [mobile, setMobile] = useState();
  const [name, setName] = useState();


  const startQuiz = () => {
    if (mobile && name) {
      // alert("succesfull login");
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Thanks for login',
        showCloseButton: false,
        showConfirmButton: false,
        timer: 2000
      })
      // <Redirect to="/Polices"/>
      localStorage.setItem("user", JSON.stringify({name:name, mobile:mobile}))
      navigate("/Polices") 
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Enter name and mobile number',
        showCloseButton: false,
        showConfirmButton: false,
        timer: 5000
      })
    }
  };
  return (
    <>
    <Home_page_header/>
    {/* <Header/> */}
    <div className="container-fluid Form_background" >
        <div className="row">
            <div className="col-md-6 mt-4 text-center">
            <img className="lottie_files w-100" style={{maxWidth:"70vh"}} src="./exams.gif"/>
            </div>
            <div className="col-md-6 text-center">
            <h3 className="login_heading">Enter mobile number to start quiz</h3>
            <img src='./quiz_logo.png' className='img_main_login mt-4'/>
            <form action="">
             {/* <div className="icon_bg"> </div> */}
              <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter name"/>
              <br/>
              <input type="number" value={mobile} onChange={(e) => setMobile(e.target.value)} placeholder="Enter mobile number"/>
              <br />

             
            </form>
            <button onClick={startQuiz} className="login_btn"> Start Quiz </button>
            </div>
        </div>
      </div>
      <Chatbot/>
     
      <Home_footer/>
    </>
  );
};

export default HomePage;
