import React from 'react'
import { Link } from 'react-router-dom';
import '../style.css';

const Home_page_header = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 WebHeads text-center">
            <img
              className="company_logo"
              src="celebalLogo.png"
              alt="image not found"
            />
            <Link to="/admin-login">
              <button style={{ float: "right", marginTop: "2%" }} className='btn btn-primary'>ADMIN DASHBOARD</button>
            </Link>

          </div>
        </div>
      </div>
    </>
  )
}

export default Home_page_header
