import React, { useState } from "react";
import { MdEmail } from "react-icons/md";
import { BsFillKeyFill } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'

const AdminLogin = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const login = () => {
        if (email == "admin@gmail.com" && password == "12345") {
            // alert("succesfull login");
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Thanks for login',
                showCloseButton: false,
                showConfirmButton: false,
                timer: 5000
            })
            // <Redirect to="/Polices"/>
            navigate("/admin-dashboard")
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Wrong Credentials',
                showCloseButton: false,
                showConfirmButton: false,
                timer: 5000
            })
        }
    };
    return (
        <div>
            <div className="container-fluid Form_background" >
                <div className="row">
                    <div className="col-md-6 mt-4 text-center">
                        <img className="lottie_files w-100" style={{ maxWidth: "70vh" }} src="./exams.gif" />
                    </div>
                    <div className="col-md-6 text-center">
                        <h3 className="login_heading">Login</h3>
                        <img src='./quiz_logo.png' className='img_main_login mt-4' />
                        <form action="">
                            <div className="icon_bg"> <MdEmail className="email_icon" /></div>
                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter Your Email" />
                            <br />
                            <div className="icon_bg"><BsFillKeyFill className="email_icon" /></div>
                            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Enter Your Password" />
                            <br />
                        </form>
                        <button onClick={login} className="login_btn"> Login </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AdminLogin