
// SKIP_PREFLIGHT_CHECK=true
module.exports = {
  development: {
    site_api: "http://localhost:5000"

  },
  production: {
    site_api: "http://localhost:5000"
  },
};
