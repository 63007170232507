import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Chatbot from "./Chatbot/Chatbot";

const QuizList = () => {
  return (
    <>
    <Header/>
      <div className="container">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <h4 className="mt-3">
              Please select the language which you want to attempt quiz.
            </h4>
          </div>
          <div className="col-md-2"></div>
          {/* <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">

              <div class="card" style={{ width: "15rem" }}>
                <img class="card-img-top" src="./java.png" alt="Card image cap"/>
                <div class="card-body">
                  <h5 class="card-title text-center">Java</h5>
                  <p class="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  <button className="language_button btn-primary">
                    <Link className="language_button" to={"/Java"}>
                      Java
                    </Link>
                  </button>
                </div>
              </div>

              <button className="language_button">
                <Link className="language_button" to={"/Java"}>
                  Java
                </Link>
              </button>
              <br />
              <br />
              <button className="language_button">
                <Link className="language_button" to={"/Html"}>
                  HTML
                </Link>
              </button>
              <br />
              <br />
              <button className="language_button">
                <Link className="language_button" to={"/Python"}>
                  Python
                </Link>
              </button>
              <br />
              <br />
              <button className="language_button">
                <Link className="language_button" to={"/Cprograming"}>
                  C Programing
                </Link>
              </button>
            </div>
            <div className="col-md-2"></div>
          </div> */}





          <div className="row">
            <div className="col-md-3 col-sm-6 col-lg-3 card_center_class">
            <div class="card" style={{ width: "15rem" }}>
               <center> <img class="card-img-top mt-1" src="./java.png" alt="Card image cap"/>
                <div class="card-body">
                  <h5 class="card-title text-center">JAVA</h5>
                  <p class="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  <button className="language_button btn-primary">
                    <Link className="language_button" to={"/start-quiz/java"}>
                      JAVA
                    </Link>
                  </button>
                </div></center>
              </div>

            </div>
            <div className="col-md-3 col-sm-6 col-lg-3 card_center_class">
              <div class="card" style={{ width: "15rem" }}>
               <center> <img class="card-img-top mt-1" src="./html.png" alt="Card image cap"/>
                <div class="card-body">
                  <h5 class="card-title text-center">HTML</h5>
                  <p class="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  <button className="language_button btn-primary">
                    <Link className="language_button" to={"/start-quiz/html"}>
                      HTML
                    </Link>
                  </button>
                </div>
                </center>
              </div>

            </div>
            <div className="col-md-3 col-sm-6 col-lg-3 card_center_class">
              <div class="card" style={{ width: "15rem" }}>
                <center><img class="card-img-top mt-1" src="./python.png" alt="Card image cap"/>
                <div class="card-body">
                  <h5 class="card-title text-center">PYTHON</h5>
                  <p class="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  <button className="language_button btn-primary">
                    <Link className="language_button" to={"/start-quiz/python"}>
                      PYTHON
                    </Link>
                  </button>
                </div>
                </center>
              </div>

            </div>
            <div className="col-md-3 col-sm-6 col-lg-3 card_center_class">
              <div class="card" style={{ width: "15rem" }}>
               <center><img class="card-img-top mt-1" src="./clanguage.png" alt="Card image cap"/>
                <div class="card-body">
                  <h5 class="card-title text-center">C PROGRAMING</h5>
                  <p class="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  <button className="language_button btn-primary">
                    <Link className="language_button" to={"/start-quiz/c"}>
                      C PROGRAMING
                    </Link>
                  </button>
                </div>
                </center>
              </div>

            </div>
          </div>
        </div>
      </div>
      <Chatbot/>
    </>
  );
};

export default QuizList;
